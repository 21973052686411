import React from "react";
import {Icon} from "@autopay.io/style";

type Status = "SUCCESS" | "ERROR";

interface PlateWithStatusProps {
    plateId: string;
    statusIcon?: Status
}

export const PlateWithStatus = (props: PlateWithStatusProps) => {
    if (props.statusIcon === "SUCCESS") {
        return <div className='vehicle-reg-container'>
            <div className={'padding_icon'}>
                <Icon icon="check_green" size={65} className="valid-icon" />
                <span className={'vehicle-reg'}>{props.plateId}</span>
            </div>
        </div>;
    } else if (props.statusIcon === "ERROR") {
        return (
            <div className="vehicle-reg-container">
                <div className={"padding_icon"}>
                    <Icon icon="excl_red" className={"warning-icon"} size={65}/>
                    <span className={"vehicle-reg"}>{props.plateId}</span>
                </div>
            </div>
        );
    } else {
        return <div className='vehicle-reg-container plain-vehicle-reg-container'>
            <div className={'padding_icon plain-vehicle-reg-container'}>
                <span className={'vehicle-reg'}>{props.plateId}</span>
            </div>
        </div>;
    }
}

export default PlateWithStatus;
