import React from 'react';
import {Translation} from "@autopay.io/translation";
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Button from "@autopay.io/style/lib/components/Button/Button";
import {Icon} from "@autopay.io/style";

interface DeviceDeletedProps {
    resetDevice: () => void;
}

const DeviceDeleted = (props: DeviceDeletedProps) => {
    const onResetClick = () => {
        localStorage.clear();
        props.resetDevice();
    }

    return (
        <>
            <Icon icon="excl_red" className="icon" />
            <h1>{Translation.messages().tapnpark.device.error_device_deleted}</h1>
            <Spacer />
            <Button color="primary" onClick={onResetClick}>{Translation.messages().tapnpark.device.new_device}</Button>
        </>
    );
}

export default DeviceDeleted;
