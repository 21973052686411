import {Translation} from "@autopay.io/translation";
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Button from "@autopay.io/style/lib/components/Button/Button";
import React from "react";
import {Icon} from "@autopay.io/style";

interface ConcurrentLimitExceededProps {
    reset: () => void;
}

const ConcurrentLimitExceeded = (props: ConcurrentLimitExceededProps) => {
    const onResetClick = () => {
        props.reset();
    };

    return (
        <>
            <Icon icon="excl_red" className="icon" />
            <h1>{Translation.messages().tapnpark.device.error_concurrent_limit_exceeded}</h1>
            <Spacer />
            <Button color="primary" onClick={onResetClick}>{Translation.messages().tapnpark.device.reset}</Button>
        </>
    );
};

export default ConcurrentLimitExceeded;
