import React, {useEffect} from 'react';
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Util from "../../../../components/util/util";
import Button from "reactstrap/lib/Button";
import {Translation} from "@autopay.io/translation";
import {constants} from "../../../../constants";
import ReactHtmlParser from 'react-html-parser';
import moment from "moment";
import {PlateWithStatus} from "../../components/PlateWithStatus";
import {DisplayVehicleCharacteristics} from "../../components/DisplayVehicleCharacteristics";

interface SuccessProps {
    plateId?: string;
    duration: number | null;
    startTime: string | null;
    endTime: string | null;
    reset: () => void;
    vehicleBrand?: string;
    vehicleModel?: string;
    vehicleColor?: string;
}

const Success = (props: SuccessProps) => {
    const {startTime, endTime = '2022.01.22', duration, reset} = props;

    useEffect(() => {
        setTimeout(() => props.reset(), constants.TIMEOUT_TIME);
    }, []);

    const getValidationMsg = () => {
        if (startTime && endTime) {
            const momentStartTime = moment.parseZone(startTime);
            const momentEndTime = moment.parseZone(endTime);
            const currentFacilityTime = moment();
            const showDate = momentStartTime.isSame(momentEndTime, "day") ?
                !momentStartTime.isSame(currentFacilityTime, "day") : true;

            return (
                <div className="parking-validated">
                    <span className={"parking-validated-title"}>
                        {Translation.messages().tapnpark.device.free_parking}
                    </span>
                    <span className={"parking-validated-title"}>
                        {ReactHtmlParser(Translation.tokenize(Translation.messages().tapnpark.device.time_until)
                            .setValue("startTime", Util.formatDateTime(startTime, showDate))
                            .setValue("endTime", Util.formatDateTime(endTime, showDate))
                            .buildString())}
                    </span>
                </div>
            );
        }

        return (
            <div className="parking-validated">
                <span className={"parking-validated-title"}>
                    {duration ? Util.secondsToHms(duration) : ""}{" "}
                    {Translation.messages().tapnpark.device.title}
                </span>
            </div>
        );
    };

    const translationFallback = (translationMessage: string, fallBackMessage: string) => {
        if (translationMessage !== '') {
            return translationMessage;
        }
        return fallBackMessage
    }

    const getSubTitleMsg = () => {
        const momentStartTime = moment.parseZone(startTime);
        const momentEndTime = moment.parseZone(endTime);
        const currentFacilityTime = moment();
        const showDate = momentStartTime.isSame(momentEndTime, "day")
            ? !momentStartTime.isSame(currentFacilityTime, "day")
            : true;

        return (
            <div className="parking-validated">
                <span className={"parking-validated-subtitle"}>
                {endTime ? ReactHtmlParser(translationFallback(Translation.tokenize(
                    Translation.messages().tapnpark.device.free_period_endtime)
                        .setValue("endTime", Util.formatDateTime(endTime, showDate))
                        .buildString(),
                    Translation.messages().tapnpark.device.free_period_endtime))
                : Translation.messages().tapnpark.device.free_period_ends_on_exit}
                </span>
            </div>
        );
    };

    return (
        <>
            <PlateWithStatus plateId={props.plateId || ""} statusIcon={"SUCCESS"}/>
            {props.vehicleBrand && (
                <>
                    <DisplayVehicleCharacteristics
                        vehicleBrand={props.vehicleBrand}
                        vehicleModel={props.vehicleModel}
                        vehicleColor={props.vehicleColor}
                    />
                    <Spacer size={"lg"}/>
                </>
            )}
            {getValidationMsg()}
            <Spacer size={"sm"}/>
            {getSubTitleMsg()}
            <Spacer size={"md"}/>
            <Button color="primary" onClick={reset}>
                {Translation.messages().tapnpark.device.reset}
            </Button>
        </>
    );
};

export default Success;
