import React from "react";
import {Translation} from "@autopay.io/translation";
import {colorDefinitions} from "@autopay.io/style/lib/definitions/colorDefinitions";

interface DisplayVehicleColorProps {
    vehicleColor?: string;
    vehicleBrand: string;
    vehicleModel?: string
}

interface VehicleColorInformation {
    hexCode?: string,
    translation?: string
}

const getColor = (colorCode?: string): VehicleColorInformation => {
    const green = '#21B44C';
    const grey = '#6E6E6E';
    const beige = '#EED9C4';
    const brown = '#8B4513';
    const orange = '#E17400';
    const silver = '#C0C0C0';
    const gold = '#D4AF37';
    const pink = '#FF69B4';

    switch (colorCode) {
        case "Hvit":
            return {
                hexCode: colorDefinitions.primaryWhite,
                translation: Translation.messages().tapnpark.device.colors.white
            }
        case "Rød":
            return {hexCode: colorDefinitions.pink80, translation: Translation.messages().tapnpark.device.colors.red}
        case "Blå":
            return {
                hexCode: colorDefinitions.skyBlue60,
                translation: Translation.messages().tapnpark.device.colors.blue
            }
        case "Grønn":
            return {hexCode: green, translation: Translation.messages().tapnpark.device.colors.green}
        case "Grå":
            return {hexCode: grey, translation: Translation.messages().tapnpark.device.colors.grey}
        case "Svart":
            return {
                hexCode: colorDefinitions.black100,
                translation: Translation.messages().tapnpark.device.colors.black
            }
        case "Beige":
            return {hexCode: beige, translation: Translation.messages().tapnpark.device.colors.beige}
        case "Brun":
            return {hexCode: brown, translation: Translation.messages().tapnpark.device.colors.brown}
        case "Gul":
            return {
                hexCode: colorDefinitions.yellow60,
                translation: Translation.messages().tapnpark.device.colors.yellow
            }
        case "Oransje":
            return {hexCode: orange, translation: Translation.messages().tapnpark.device.colors.orange}
        case "Sølv":
            return {hexCode: silver, translation: Translation.messages().tapnpark.device.colors.silver}
        case "Gull":
            return {hexCode: gold, translation: Translation.messages().tapnpark.device.colors.gold}
        case "Rosa":
            return {hexCode: pink, translation: Translation.messages().tapnpark.device.colors.pink}
        case "Fiolett, lilla":
            return {
                hexCode: colorDefinitions.purple60primary,
                translation: Translation.messages().tapnpark.device.colors.purple
            }
        case "Turkis":
            return {
                hexCode: colorDefinitions.turquoise60,
                translation: Translation.messages().tapnpark.device.colors.turquoise
            }
        case "Annen":
            return {
                hexCode: colorDefinitions.blue100primary,
                translation: Translation.messages().tapnpark.device.colors.multicolored
            }
        default:
            return {hexCode: undefined, translation: undefined}
    }
}

const getCircle = (colorCode?: string) => {
    switch (colorCode) {
        case "Annen":
            return 'vehicle-color split-circle'
        default:
            return 'vehicle-color'
    }
}

const vehicleModel = (brand: string, model?: string) => {
    if (model?.startsWith(brand)) {
        const brandLength = brand.length;
        return model.substring(brandLength);
    } else {
        return model;
    }
}

export const DisplayVehicleCharacteristics = (props: DisplayVehicleColorProps) => {
    if (getColor(props.vehicleColor).hexCode !== undefined && getColor(props.vehicleColor).translation !== undefined) {
        return <div className={'vehicle-characteristics'}>
            <span
                className={'vehicle-type'}>{props.vehicleBrand} {vehicleModel(props.vehicleBrand, props.vehicleModel)}</span>
            <span className={getCircle(props.vehicleColor)}
                  style={{backgroundColor: getColor(props.vehicleColor).hexCode}}>
        </span>
            <span className={'vehicle-type'}>{getColor(props.vehicleColor).translation}</span>
        </div>;
    } else {
        return <div className={'vehicle-characteristics'}>
            <span
                className={'vehicle-type'}>{props.vehicleBrand} {vehicleModel(props.vehicleBrand, props.vehicleModel)}</span>
        </div>;
    }
}
