import React, {Fragment, SFC} from 'react';
import Container from 'reactstrap/lib/Container';
import {AioLanguageSwitcher, AioPage} from "@autopay.io/style";
import {Translation} from "@autopay.io/translation";

const AppContainer: SFC = ({children}) => (
    <Fragment>
        <AioPage
            type="page-with-header"
            header={{
                style: 'orange',
                right: [{
                    element: <AioLanguageSwitcher
                        supportedLanguages={Translation.supportedLanguages}
                        setSelectedLanguage={Translation.setSelectedLanguage}
                        getSelectedLanguageName={Translation.getSelectedLanguageName}
                    />,
                }],
            }}
        >
            <Container fluid={true} className='main'>
                {children}
            </Container>
        </AioPage>
    </Fragment>
);

export default AppContainer;
